"use client";
import {
  Aggregation$,
  all_aggregations,
  PropertyReference$,
} from "@bumblebee/core/api/projection_edits";
import { Tabs, Text, VStack } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { LuSearchX } from "react-icons/lu";

import DomainPropertySelect from "@/components/DomainPropertySelect";
import GenericRadioCard from "@/components/GenericRadioCard";
import Overlay from "@/components/Overlay";
import ProjectionEditor from "@/components/states/ProjectionEditor";
import { EmptyState } from "@/components/ui/empty-state";
import { useAppContext } from "@/context/AppContext";
import { useProjectionContext } from "@/context/ProjectionContext";
import { CEvents } from "@/lib/state-machine";

const AggregationHandler = () => {
  const portalRef = useRef<HTMLDivElement>(null);
  const { domainProperties, addMeasure } = useProjectionContext();
  const { onStateChange } = useAppContext();
  const [aggType, setAggType] = useState<Aggregation$ | null>(null);
  const [aggProperty, setAggProperty] = useState<PropertyReference$ | null>(
    null,
  );

  const handleContinue = () => {
    if (aggType == null || aggProperty == null) {
      throw new Error("Aggregation type or property is missing");
    }
    addMeasure({
      property: aggProperty,
      aggregation: aggType,
    });
    onStateChange(CEvents.HANDLER_COMPLETE);
  };

  return (
    <ProjectionEditor>
      <Overlay
        header="Add Measure"
        subheader="Choose the operation to perform on your data."
        portalRef={portalRef}
        onContinue={handleContinue}
        isContinueDisabled={aggType == null || aggProperty == null}
      >
        {domainProperties.length === 0 ? (
          <EmptyState
            icon={<LuSearchX />}
            title="No domain properties"
            description="Your domain must contain at least one property in order to create an aggregation"
          />
        ) : (
          <Tabs.Root defaultValue="main" variant={"enclosed"} w={"100%"}>
            <Tabs.List w={"100%"}>
              <Tabs.Trigger value="main">Aggregation 1</Tabs.Trigger>
              <Tabs.Trigger value="sub">Add Sub-aggregation</Tabs.Trigger>
            </Tabs.List>
            <Tabs.Content
              value="main"
              display={"flex"}
              flexDir={"column"}
              gap={4}
              pt={8}
            >
              <VStack gap={4} align={"stretch"}>
                <Text fontWeight={"bold"}>Measure Configuration</Text>
                <GenericRadioCard
                  label={"Aggregation Type"}
                  options={all_aggregations.toArray()}
                  onChange={setAggType}
                  formatOptionLabel={(name) => `${name.toUpperCase()}()`}
                />

                <DomainPropertySelect
                  label={"Domain Property"}
                  placeholder={"Select Domain Property"}
                  portalRef={portalRef}
                  onChange={setAggProperty}
                />
              </VStack>
            </Tabs.Content>
            <Tabs.Content value="sub">SUB CONTENT</Tabs.Content>
          </Tabs.Root>
        )}
      </Overlay>
    </ProjectionEditor>
  );
};

export default AggregationHandler;
