import { Grid, Text, VStack } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

import QueryResults from "@/components/QueryResults";

import DomainElements from "./DomainElements";
import FilterElements from "./FilterElements";
import MeasureElements from "./MeasureElements";

const ProjectionEditor = ({ children }: PropsWithChildren) => {
  return (
    <VStack alignItems={"stretch"} gap={8}>
      <Text fontSize={"3xl"} fontWeight={"medium"}>
        Analysis Overview
      </Text>
      <Grid templateColumns={"1fr 1fr 1fr"} gap={8}>
        <VStack alignItems={"stretch"} gap={4}>
          <Text fontSize="xl" fontWeight="medium">
            Domain
          </Text>
          <DomainElements />
        </VStack>
        <VStack alignItems={"stretch"} gap={4}>
          <Text fontSize="xl" fontWeight="medium">
            Measures
          </Text>
          <MeasureElements />
        </VStack>
        <VStack alignItems={"stretch"} gap={4}>
          <Text fontSize="xl" fontWeight="medium">
            Filters
          </Text>
          <FilterElements />
        </VStack>
      </Grid>
      <VStack alignItems={"stretch"} gap={4}>
        <Text fontSize="xl" fontWeight="medium">
          Preview
        </Text>
        <QueryResults />
      </VStack>

      {children}
    </VStack>
  );
};

export default ProjectionEditor;
