"use client";
import { PropertyReference } from "@bumblebee/core/api/projection_edits";
import { useState } from "react";

import EntityExplorer from "@/components/EntityExplorer";
import Overlay from "@/components/Overlay";
import ProjectionEditor from "@/components/states/ProjectionEditor";
import { useAppContext } from "@/context/AppContext";
import { useProjectionContext } from "@/context/ProjectionContext";
import { CEvents } from "@/lib/state-machine";

const DomainElementEditor = () => {
  const handleContinue = () => {
    if (selection.length === 0) {
      throw new Error("Domain element selection is missing");
    }
    addDomainProperties(selection);
    onStateChange(CEvents.CREATE_PROJECTION);
  };
  const { addDomainProperties } = useProjectionContext();
  const [selection, setSelection] = useState<PropertyReference[]>([]);
  const { onStateChange } = useAppContext();
  const hasSelection = selection.length > 0;

  return (
    <ProjectionEditor>
      <Overlay
        header="Add Properties"
        subheader="Choose properties to add to your Domain."
        onContinue={handleContinue}
        isContinueDisabled={!hasSelection}
      >
        <EntityExplorer onSelectionChange={setSelection} />
      </Overlay>
    </ProjectionEditor>
  );
};

export default DomainElementEditor;
