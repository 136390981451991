import { CustomType } from "@bumblebee/core/gleam";
import { HStack, VStack } from "@chakra-ui/react";
import { useMemo } from "react";

import {
  RadioCardItem,
  RadioCardLabel,
  RadioCardRoot,
} from "@/components/ui/radio-card";

interface Props<T> {
  onChange: (v: T | null) => void;
  options: T[];
  label: string;
  defaultValueIndex?: number;
  formatOptionLabel?: (name: string) => string;
  formatOptionDescription?: (name: string) => string;
}

const GenericSelect = <T extends InstanceType<typeof CustomType> = never>({
  options,
  label,
  formatOptionLabel,
  formatOptionDescription,
  defaultValueIndex,
  onChange,
}: Props<T> &
  ([T] extends [never]
    ? { error: "Generic parameter T is required" }
    : {})) => {
  const collection = useMemo(
    () =>
      options.map((o) => ({
        label:
          formatOptionLabel != null
            ? formatOptionLabel(o.constructor.name)
            : o.constructor.name,
        value: o.constructor.name,
        get: () => o,
      })),
    [options, formatOptionLabel],
  );

  return (
    <RadioCardRoot
      gap={8}
      onValueChange={(v) => {
        onChange(collection.find((e) => e.value === v.value)!.get());
      }}
      defaultValue={
        defaultValueIndex != null
          ? collection[defaultValueIndex].value
          : undefined
      }
    >
      <VStack align={"stretch"}>
        <RadioCardLabel fontWeight={"medium"}>{label}</RadioCardLabel>
        <HStack align="stretch">
          {collection.map((o) => (
            <RadioCardItem
              description={
                formatOptionDescription != null
                  ? formatOptionDescription(o.value)
                  : undefined
              }
              label={o.label}
              key={o.value}
              value={o.value}
              _hover={{ cursor: "pointer" }}
            />
          ))}
        </HStack>
      </VStack>
    </RadioCardRoot>
  );
};

export default GenericSelect;
