"use client";

import { Button, Table } from "@chakra-ui/react";
import { LuAlignJustify } from "react-icons/lu";

import { EmptyState } from "@/components/ui/empty-state";
import { useQueryContext } from "@/context/QueryContext";

const EmptyTable = ({ onClick }: { onClick: () => void }) => {
  return (
    <Table.Root variant={"outline"} borderRadius={"lg"}>
      <Table.Header>
        <Table.Row>
          <Table.ColumnHeader></Table.ColumnHeader>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <EmptyState
              icon={<LuAlignJustify />}
              title="No results to display"
              description="Execute your projection to see results here."
            >
              <Button onClick={onClick}>Execute Projection</Button>
            </EmptyState>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table.Root>
  );
};

const ResultsTable = () => {
  const { queryResponse, executeProjection } = useQueryContext();
  if (queryResponse == null) {
    return <EmptyTable onClick={executeProjection} />;
  }

  // TODO: use column type anywhere?
  const headers = queryResponse.columns.map((c) => c.name);

  const textAlignment = (colIndex: number): string => {
    return headers.length > 1 && colIndex === headers.length - 1
      ? "end"
      : "unset";
  };

  return (
    <Table.Root variant={"outline"} borderRadius={"lg"} fontFamily="monospace">
      <Table.Header>
        <Table.Row>
          {headers.map((column, idx) => (
            <Table.ColumnHeader key={column} textAlign={textAlignment(idx)}>
              {column}
            </Table.ColumnHeader>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {queryResponse.rows.map((row, rowIdx) => (
          <Table.Row key={rowIdx}>
            {headers.map((column, colIdx) => (
              <Table.Cell
                textAlign={textAlignment(colIdx)}
                key={`${rowIdx}-${column}`}
              >
                {row[column]}
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  );
};

export default ResultsTable;
