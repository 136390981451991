import { Tabs } from "@chakra-ui/react";

import CodeBlock from "./CodeBlock";
import ResultsTable from "./ResultsTable";

const QueryResults = () => {
  return (
    <>
      <Tabs.Root defaultValue="data" variant={"enclosed"}>
        <Tabs.List>
          <Tabs.Trigger value="data">Query Result</Tabs.Trigger>
          <Tabs.Trigger value="query">SQL Query</Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content value="data">
          <ResultsTable />
        </Tabs.Content>
        <Tabs.Content value="query">
          <CodeBlock />
        </Tabs.Content>
      </Tabs.Root>
    </>
  );
};

export default QueryResults;
