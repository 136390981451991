"use client";

import {
  DomainElement$,
  DomainProperty,
} from "@bumblebee/core/api/projection_edits";
import { property_type_to_string } from "@bumblebee/core/buzz_model";
import * as option from "@bumblebee/core/gleam/option";
import { find_property_reference_by_api_reference } from "@bumblebee/core/projection";
import { Button, Center, Code, IconButton, Table } from "@chakra-ui/react";
import { useCallback } from "react";
import { LuPlus, LuX } from "react-icons/lu";

import EmptyState from "@/components/EmptyState";
import { useAppContext } from "@/context/AppContext";
import { useProjectionContext } from "@/context/ProjectionContext";
import { CEvents } from "@/lib/state-machine";
import { getKeyForElement } from "@/utils";

const DomainElements = () => {
  const { projection, domainProperties, removeDomainProperty } =
    useProjectionContext();
  const { onStateChange } = useAppContext();
  const addDomainElement = () => {
    onStateChange(CEvents.CREATE_EDIT_DOMAIN);
  };

  const getElementType = useCallback(
    (element: DomainElement$): string => {
      if (element instanceof DomainProperty) {
        const prop = find_property_reference_by_api_reference(
          projection,
          element.property,
        );
        return option.unwrap(
          option.map(prop, (p) => {
            return property_type_to_string(p.property.property_type);
          }),
          "",
        );
      } else {
        return "";
      }
    },
    [projection],
  );

  return domainProperties.length > 0 ? (
    <>
      <Table.Root size="sm" variant={"outline"}>
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeader>Type</Table.ColumnHeader>
            <Table.ColumnHeader>Entity</Table.ColumnHeader>
            <Table.ColumnHeader>Property</Table.ColumnHeader>
            <Table.ColumnHeader>Property Type</Table.ColumnHeader>
            <Table.ColumnHeader></Table.ColumnHeader>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {domainProperties.map((property) => (
            <Table.Row key={getKeyForElement(property)}>
              <Table.Cell>
                <Code>{property.constructor.name}</Code>
              </Table.Cell>
              <Table.Cell>
                <Code>{property.property.entity_name}</Code>
              </Table.Cell>
              <Table.Cell>
                <Code>{property.property.property_name}</Code>
              </Table.Cell>
              <Table.Cell>
                <Code>{getElementType(property)}</Code>
              </Table.Cell>
              <Table.Cell>
                <IconButton
                  size={"xs"}
                  variant={"outline"}
                  onClick={() => {
                    removeDomainProperty(property);
                  }}
                >
                  <LuX />
                </IconButton>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>

      <Center my={8}>
        <Button onClick={addDomainElement} size={"xs"} variant={"outline"}>
          <LuPlus /> Add Domain Element
        </Button>
      </Center>
    </>
  ) : (
    <EmptyState
      title="Your projection has no domain elements"
      description="Add domain elements to get started"
      buttonText="Add Domain Elements"
      onClick={addDomainElement}
    />
  );
};

export default DomainElements;
