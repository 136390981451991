import { PropertyReference } from "@bumblebee/core/api/projection_edits";
import {
  Code,
  createListCollection,
  SelectValueChangeDetails,
} from "@chakra-ui/react";
import { RefObject, useMemo } from "react";

import {
  SelectContent,
  SelectItem,
  SelectItemGroup,
  SelectLabel,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
} from "@/components/ui/select";
import { useProjectionContext } from "@/context/ProjectionContext";

interface Props {
  portalRef: RefObject<HTMLDivElement>;
  onChange: (prop: PropertyReference | null) => void;
  label: string;
  placeholder: string;
}

interface PropertyReferenceSelectItem {
  label: string;
  value: string;
  group: string;
  // must make this a getter to avoid turning class instance into Proxy object
  get: () => PropertyReference;
}

// Select a PropertyReference from your current domain
const DomainPropertySelect = ({
  portalRef,
  onChange,
  label,
  placeholder,
}: Props) => {
  const { domainProperties } = useProjectionContext();
  const properties = useMemo(
    () =>
      createListCollection<PropertyReferenceSelectItem>({
        items: domainProperties.map((domainProperty) => ({
          label: domainProperty.property.property_name,
          value: `${domainProperty.property.entity_name}:${domainProperty.property.property_name}`,
          group: domainProperty.property.entity_name,
          get: () => domainProperty.property,
        })),
      }),
    [domainProperties],
  );

  const groups = useMemo(
    () =>
      properties.items.reduce(
        (acc, item) => {
          const group = acc.find((group) => group.group === item.group);
          if (group != null) {
            group.items.push(item);
          } else {
            acc.push({ group: item.group, items: [item] });
          }
          return acc;
        },
        [] as { group: string; items: (typeof properties)["items"] }[],
      ),
    [properties],
  );

  return (
    <SelectRoot
      collection={properties}
      defaultValue={["sum"]}
      size="lg"
      onValueChange={(
        change: SelectValueChangeDetails<PropertyReferenceSelectItem>,
      ) => {
        if (change.items.length === 0) {
          onChange(null);
          return;
        }

        onChange(change.items[0].get());
      }}
    >
      <SelectLabel>{label}</SelectLabel>
      <SelectTrigger clearable>
        <SelectValueText placeholder={placeholder} />
      </SelectTrigger>
      <SelectContent portalRef={portalRef}>
        {groups.map((group) => (
          <SelectItemGroup key={group.group} label={group.group}>
            {group.items.map((item) => (
              <SelectItem item={item} key={item.value}>
                <Code>{item.label}</Code>
              </SelectItem>
            ))}
          </SelectItemGroup>
        ))}
      </SelectContent>
    </SelectRoot>
  );
};

export default DomainPropertySelect;
