"use client";

import {
  Card,
  Grid,
  Link as ChakraLink,
  LinkOverlay,
  VStack,
} from "@chakra-ui/react";
import NextLink from "next/link";

import { Avatar } from "@/components/ui/avatar";
import { useAppContext } from "@/context/AppContext";
import { CStates } from "@/lib/state-machine";

interface CardContent {
  title: string;
  description: string;
  href?: string;
  cstate?: CStates;
}

const cardContents: CardContent[] = [
  {
    title: "Search",
    description:
      "Search your data for relevant entities, properties or datasets.",
    href: "#",
  },
  {
    title: "Explore Entities",
    description: "Browse entities, properties or datasets in a visual manner.",
    cstate: CStates.OBJECT_EXPLORER,
  },
  {
    title: "Chat with your data",
    description: "An easy way to interact and consume your data.",
    href: "/chat",
  },
];

const Cards = () => {
  const { navigateToCState } = useAppContext();
  return (
    <Grid templateColumns={"repeat(3, 1fr)"} columnGap={4}>
      {cardContents.map((content) => {
        const { title, description, href, cstate } = content;
        return (
          <Card.Root variant={"outline"} key={title}>
            <Card.Body gap={4} flexDirection="row">
              <Avatar name="T K" size="lg" shape="rounded" />
              <VStack alignItems={"flex-start"}>
                <Card.Title mb="2">{title}</Card.Title>
                <Card.Description>{description}</Card.Description>
                <LinkOverlay asChild href="#">
                  {href != null ? (
                    <ChakraLink variant="underline" asChild fontSize={"sm"}>
                      <NextLink href={href}>Get Started</NextLink>
                    </ChakraLink>
                  ) : cstate != null ? (
                    <ChakraLink
                      variant="underline"
                      fontSize={"sm"}
                      onClick={() => navigateToCState(cstate)}
                    >
                      Get Started
                    </ChakraLink>
                  ) : null}
                </LinkOverlay>
              </VStack>
            </Card.Body>
          </Card.Root>
        );
      })}
    </Grid>
  );
};

export default Cards;
