import { Input } from "@chakra-ui/react";

import { Field } from "@/components/ui/field";

interface Props {
  label: string;
  placeholder: string;
  onChange: (v: string) => void;
}

const StringInput = ({ label, placeholder, onChange }: Props) => {
  return (
    <Field label={label}>
      <Input
        size="xl"
        placeholder={placeholder}
        variant="outline"
        onChange={(e) => onChange(e.target.value)}
      />
    </Field>
  );
};

export default StringInput;
