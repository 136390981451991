"use client";

import { PropertyReference } from "@bumblebee/core/api/projection_edits";
import { Button, HStack, Text, VStack } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { LuPlus } from "react-icons/lu";

import EntityExplorer from "@/components/EntityExplorer";
import { useAppContext } from "@/context/AppContext";
import { useProjectionContext } from "@/context/ProjectionContext";
import { CEvents } from "@/lib/state-machine";

const ObjectExplorer = () => {
  const { addDomainProperties } = useProjectionContext();
  const [selection, setSelection] = useState<PropertyReference[]>([]);
  const { onStateChange } = useAppContext();
  const hasSelection = selection.length > 0;

  const handleCreateProjection = useCallback(() => {
    addDomainProperties(selection);
    onStateChange(CEvents.CREATE_PROJECTION);
  }, [addDomainProperties, selection, onStateChange]);

  const handleCancel = () => {
    onStateChange(CEvents.CREATE_PROJECTION);
  };

  return (
    <VStack gap={8} alignItems={"stretch"}>
      <Text fontSize={"3xl"} fontWeight={"medium"}>
        Entity Explorer
      </Text>

      <EntityExplorer onSelectionChange={setSelection} />

      <HStack py={4} justifyContent={"flex-end"} alignItems={"center"} gap={4}>
        <Button onClick={handleCancel} variant={"outline"}>
          Cancel
        </Button>

        <Button onClick={handleCreateProjection} disabled={!hasSelection}>
          <LuPlus /> Create Projection From Selection
        </Button>
      </HStack>
    </VStack>
  );
};

export default ObjectExplorer;
