import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2751084505/src/bumblebee/bumble-bi/app/src/components/QueryResults/CodeBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2751084505/src/bumblebee/bumble-bi/app/src/components/QueryResults/ResultsTable.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2751084505/src/bumblebee/bumble-bi/app/src/components/states/DomainElementEditor.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2751084505/src/bumblebee/bumble-bi/app/src/components/states/FilterHandler/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2751084505/src/bumblebee/bumble-bi/app/src/components/states/GroupHandler/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2751084505/src/bumblebee/bumble-bi/app/src/components/states/ObjectExplorer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2751084505/src/bumblebee/bumble-bi/app/src/components/states/ProjectionEditor/DomainElements.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2751084505/src/bumblebee/bumble-bi/app/src/components/states/ProjectionEditor/FilterElements.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2751084505/src/bumblebee/bumble-bi/app/src/components/states/ProjectionEditor/MeasureElements.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2751084505/src/bumblebee/bumble-bi/app/src/components/states/Workbook/Cards.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2751084505/src/bumblebee/node_modules/@chakra-ui/react/dist/esm/components/box/box.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2751084505/src/bumblebee/node_modules/@chakra-ui/react/dist/esm/components/grid/grid.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2751084505/src/bumblebee/node_modules/@chakra-ui/react/dist/esm/components/stack/v-stack.js");
;
import(/* webpackMode: "eager", webpackExports: ["TableBody","TableCaption","TableCell","TableColumn","TableColumnGroup","TableColumnHeader","TableFooter","TableHeader","TableRoot","TableRootPropsProvider","TableRow","TableScrollArea"] */ "/codebuild/output/src2751084505/src/bumblebee/node_modules/@chakra-ui/react/dist/esm/components/table/table.js");
;
import(/* webpackMode: "eager", webpackExports: ["TabsContent","TabsContentGroup","TabsContext","TabsIndicator","TabsList","TabsPropsProvider","TabsRoot","TabsRootProvider","TabsTrigger"] */ "/codebuild/output/src2751084505/src/bumblebee/node_modules/@chakra-ui/react/dist/esm/components/tabs/tabs.js");
;
import(/* webpackMode: "eager", webpackExports: ["Heading"] */ "/codebuild/output/src2751084505/src/bumblebee/node_modules/@chakra-ui/react/dist/esm/components/typography/heading.js");
;
import(/* webpackMode: "eager", webpackExports: ["Text"] */ "/codebuild/output/src2751084505/src/bumblebee/node_modules/@chakra-ui/react/dist/esm/components/typography/text.js");
