"use client";

import { fromTuple } from "@bumblebee/common/utils";
import {
  all_filter_conditions,
  all_operators,
  all_values,
  DomainProperty,
  FilterCondition$,
  FilterDomain,
  FilterMeasure,
  Operator$,
  PropertyReference,
  Value$,
} from "@bumblebee/core/api/projection_edits";
import { VStack } from "@chakra-ui/react";
import { useCallback, useRef, useState } from "react";
import { LuSearchX } from "react-icons/lu";

import DomainPropertySelect from "@/components/DomainPropertySelect";
import GenericRadioCard from "@/components/GenericRadioCard";
import Overlay from "@/components/Overlay";
import ProjectionEditor from "@/components/states/ProjectionEditor";
import StringInput from "@/components/StringInput";
import { EmptyState } from "@/components/ui/empty-state";
import { useAppContext } from "@/context/AppContext";
import { useProjectionContext } from "@/context/ProjectionContext";
import { CEvents } from "@/lib/state-machine";
import { filterValueFromLiteral } from "@/utils";

const FilterHandler = () => {
  const portalRef = useRef<HTMLDivElement>(null);
  const [filterCondition, setFilterCondition] =
    useState<FilterCondition$ | null>(null);
  const [domainProperty, setDomainProperty] =
    useState<PropertyReference | null>(null);
  const [operatorType, setOperatorType] = useState<Operator$ | null>(null);
  const [valueType, setValueType] = useState<Value$ | null>(null);
  const [valueLiteral, setValueLiteral] = useState<string | null>(null);
  const { onStateChange } = useAppContext();
  const { addFilter, domainProperties } = useProjectionContext();

  const anyValuesNull =
    domainProperty == null ||
    operatorType == null ||
    valueType == null ||
    valueLiteral == null;

  const handleAddFilter = useCallback(() => {
    if (
      domainProperty == null ||
      operatorType == null ||
      valueType == null ||
      valueLiteral == null
    ) {
      throw new Error("Filter property, operator, or value is missing");
    }
    if (filterCondition instanceof FilterMeasure) {
      throw new Error("TODO: handle FilterMeasure");
    }
    const filter = new FilterDomain(
      new DomainProperty(domainProperty),
      operatorType,
      filterValueFromLiteral(valueType, valueLiteral),
    );
    addFilter(filter);
    onStateChange(CEvents.HANDLER_COMPLETE);
  }, [
    filterCondition,
    domainProperty,
    operatorType,
    valueType,
    valueLiteral,
    addFilter,
    onStateChange,
  ]);

  return (
    <ProjectionEditor>
      <Overlay
        portalRef={portalRef}
        header="Add Filters"
        subheader="Choose filters to add to your Analysis."
        onContinue={handleAddFilter}
        isContinueDisabled={anyValuesNull}
      >
        {domainProperties.length === 0 ? (
          <EmptyState
            icon={<LuSearchX />}
            title="No domain properties"
            description="Your domain must contain at least one property in order to create a filter"
          />
        ) : (
          <VStack gap={8} align={"stretch"}>
            <GenericRadioCard<FilterCondition$>
              label="Filter Type"
              options={fromTuple(all_filter_conditions)}
              formatOptionDescription={(value) => `Create a ${value} filter`}
              onChange={setFilterCondition}
              defaultValueIndex={0}
            />
            <DomainPropertySelect
              label={"Domain Property"}
              placeholder={"Select Domain Property"}
              portalRef={portalRef}
              onChange={setDomainProperty}
            />
            <GenericRadioCard<Operator$>
              label="Operator Type"
              options={all_operators.toArray()}
              onChange={setOperatorType}
            />

            <GenericRadioCard<Value$>
              label={"Value Type"}
              options={fromTuple(all_values)}
              onChange={setValueType}
            />

            <StringInput
              placeholder="Value"
              label="Value"
              onChange={setValueLiteral}
            />
          </VStack>
        )}
      </Overlay>
    </ProjectionEditor>
  );
};

export default FilterHandler;
