"use client";
import * as api from "@bumblebee/core/api/projection_edits";
import { Button, Center, Code, IconButton, Table } from "@chakra-ui/react";
import { LuPlus, LuX } from "react-icons/lu";

import EmptyState from "@/components/EmptyState";
import { useAppContext } from "@/context/AppContext";
import { useProjectionContext } from "@/context/ProjectionContext";
import { CStates } from "@/lib/state-machine";
import { getKeyForProjectionEdit } from "@/utils";

const MeasureElements = () => {
  const { measures, removeMeasure } = useProjectionContext();
  const { navigateToCState } = useAppContext();

  const handleAddMeasure = () => {
    navigateToCState(CStates.GROUP_HANDLER);
  };

  return measures.length > 0 ? (
    <>
      <Table.Root size="sm" variant={"outline"}>
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeader>Type</Table.ColumnHeader>
            <Table.ColumnHeader>Aggregation</Table.ColumnHeader>
            <Table.ColumnHeader>Entity</Table.ColumnHeader>
            <Table.ColumnHeader>Property</Table.ColumnHeader>
            <Table.ColumnHeader></Table.ColumnHeader>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {measures.map((measure) => {
            return (
              <Table.Row key={getKeyForProjectionEdit(measure)}>
                {measure.expression instanceof api.Property ? (
                  <>
                    <Table.Cell>
                      <Code>{measure.expression.constructor.name}</Code>
                    </Table.Cell>
                    <Table.Cell>
                      <Code>
                        {api.aggregation_to_string(measure.aggregation)}
                      </Code>
                    </Table.Cell>
                    <Table.Cell>
                      <Code>{measure.expression.property.entity_name}</Code>
                    </Table.Cell>
                    <Table.Cell>
                      <Code>{measure.expression.property.property_name}</Code>
                    </Table.Cell>

                    <Table.Cell>
                      <IconButton
                        size={"xs"}
                        variant={"outline"}
                        onClick={() => {
                          removeMeasure(measure);
                        }}
                      >
                        <LuX />
                      </IconButton>
                    </Table.Cell>
                  </>
                ) : (
                  (() => {
                    // TODO: undhandled case where measure is type MeasureProjection
                    throw new Error(
                      "Unhandled case in MeasureElements component",
                    );
                  })()
                )}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table.Root>
      <Center my={8}>
        <Button onClick={handleAddMeasure} size={"xs"} variant={"outline"}>
          <LuPlus /> Add Measure
        </Button>
      </Center>
    </>
  ) : (
    <EmptyState
      title="Your projection has no measures"
      description="Add measures to get started"
      buttonText="Add Measure"
      onClick={handleAddMeasure}
    />
  );
};

export default MeasureElements;
