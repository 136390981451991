"use client";
import { PropertyReference } from "@bumblebee/core/api/projection_edits";
import * as buzz from "@bumblebee/core/buzz_model";
import { Badge, Button, IconButton, Table } from "@chakra-ui/react";
import { ReactNode, useCallback, useEffect, useState } from "react";
import {
  LuCalendarDays,
  LuCopyCheck,
  LuExternalLink,
  LuHash,
  LuRemoveFormatting,
} from "react-icons/lu";

import { ToggleTip } from "@/components/ui/toggle-tip";
//import { usePreviewContext } from "@/context/PreviewContext";
import { useProjectionContext } from "@/context/ProjectionContext";
import {
  domainPropertiesHasProperty,
  entityOutgoingRelationships,
  isSamePropertyReference,
  propertyReferencesHasProperty,
  propertyToPropertyReference,
} from "@/utils";

const iconSize = "8px";

const typeIconMap: Record<string, ReactNode> = {
  String: <LuRemoveFormatting size={iconSize} />,
  Integer: <LuHash size={iconSize} />,
  Float: <LuHash size={iconSize} />,
  Boolean: <LuCopyCheck size={iconSize} />,
  Timestamp: <LuCalendarDays size={iconSize} />,
};

interface ColumnsTableProps {
  onSelectionChange: (selection: PropertyReference[]) => void;
}

const getKeyForProperty = (property: buzz.Property) =>
  `${property.name}.${property.column_name}`;

const EntityExplorer = ({ onSelectionChange }: ColumnsTableProps) => {
  const { model, domainProperties } = useProjectionContext();
  //const { setSelectedEntity } = usePreviewContext();
  const [selection, setSelection] = useState<PropertyReference[]>([]);
  const handleCheckedChange = useCallback(
    (changes: { checked: boolean; value: PropertyReference }) => {
      setSelection((prev) =>
        changes.checked
          ? [...prev, changes.value]
          : selection.filter(
              (pr) => !isSamePropertyReference(pr, changes.value),
            ),
      );
    },
    [selection],
  );

  useEffect(() => {
    onSelectionChange(selection);
  }, [selection, onSelectionChange]);

  return (
    <Table.Root variant={"outline"}>
      <Table.Header>
        <Table.Row>
          <Table.ColumnHeader>Name</Table.ColumnHeader>
          <Table.ColumnHeader>Properties</Table.ColumnHeader>
          <Table.ColumnHeader textAlign="end">
            Linked Entities
          </Table.ColumnHeader>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {model.entities.toArray().map((entity) => (
          <Table.Row key={entity.name}>
            <Table.Cell>{entity.name}</Table.Cell>
            <Table.Cell maxW={"220px"}>
              {entity.properties.toArray().map((property) => {
                const isSelected = propertyReferencesHasProperty(
                  selection,
                  entity,
                  property,
                );
                const isDisabled = domainPropertiesHasProperty(
                  domainProperties,
                  entity,
                  property,
                );
                return (
                  <Button
                    p={2}
                    h={6}
                    variant={isSelected ? "solid" : "surface"}
                    disabled={isDisabled}
                    size={"sm"}
                    key={getKeyForProperty(property)}
                    mr={2}
                    mb={2}
                    alignItems={"center"}
                    onClick={() => {
                      handleCheckedChange({
                        checked: !isSelected,
                        value: propertyToPropertyReference(entity, property),
                      });
                    }}
                  >
                    {
                      typeIconMap[
                        buzz.property_type_to_string(property.property_type)
                      ]
                    }
                    {property.name}
                  </Button>
                );
              })}
            </Table.Cell>
            <Table.Cell textAlign="end">
              <ToggleTip
                content={entityOutgoingRelationships(entity, model).map((e) => (
                  <Badge
                    key={e.name}
                    mr={2}
                    mb={2}
                    size={"md"}
                    variant={"surface"}
                  >
                    {e.name}
                  </Badge>
                ))}
              >
                <IconButton
                  aria-label="Preview"
                  variant={"surface"}
                  size={"xs"}
                  onClick={() => {
                    //setSelectedEntity(entity);
                  }}
                >
                  <LuExternalLink />
                </IconButton>
              </ToggleTip>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  );
};
export default EntityExplorer;
