import { Text, VStack } from "@chakra-ui/react";
import { ReactNode, RefObject } from "react";

import { Button } from "@/components/ui/button";
import {
  DrawerActionTrigger,
  DrawerBackdrop,
  DrawerBody,
  DrawerCloseTrigger,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerRoot,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { useAppContext } from "@/context/AppContext";

interface Props {
  portalRef?: RefObject<HTMLDivElement>;
  children: ReactNode;
  header: string;
  subheader?: string;
  continueText?: string;
  onContinue: () => void;
  isContinueDisabled: boolean;
}

const Overlay = ({
  children,
  header,
  subheader,
  continueText,
  onContinue,
  isContinueDisabled,
  portalRef,
}: Props) => {
  const { navigateBack } = useAppContext();

  return (
    <DrawerRoot
      size={"xl"}
      defaultOpen
      onOpenChange={(details) => {
        if (!details.open) {
          navigateBack();
        }
      }}
    >
      <DrawerBackdrop />
      <DrawerTrigger asChild>
        <Button variant="outline" size="sm">
          Open
        </Button>
      </DrawerTrigger>
      <DrawerContent ref={portalRef}>
        <DrawerHeader>
          <DrawerTitle>{header}</DrawerTitle>
        </DrawerHeader>
        <DrawerBody>
          {subheader != null && <Text mb={8}>{subheader}</Text>}
          <VStack alignItems={"stretch"} gap={8}>
            {children}
          </VStack>
        </DrawerBody>
        <DrawerFooter>
          <DrawerActionTrigger asChild>
            <Button variant="outline">Cancel</Button>
          </DrawerActionTrigger>
          <Button onClick={onContinue} disabled={isContinueDisabled}>
            {continueText || "Continue"}
          </Button>
        </DrawerFooter>
        <DrawerCloseTrigger />
      </DrawerContent>
    </DrawerRoot>
  );
};

export default Overlay;
