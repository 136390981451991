"use client";
import {
  filter_condition_to_string,
  operator_to_string,
  value_to_string,
} from "@bumblebee/core/api/projection_edits";
import { Button, Center, Code, IconButton, Table } from "@chakra-ui/react";
import { LuPlus, LuX } from "react-icons/lu";

import EmptyState from "@/components/EmptyState";
import { useAppContext } from "@/context/AppContext";
import { useProjectionContext } from "@/context/ProjectionContext";
import { CEvents } from "@/lib/state-machine";
import { propertyReferenceFromFilterCondition } from "@/utils";

const FilterElements = () => {
  const { filters, removeFilter } = useProjectionContext();
  const { onStateChange } = useAppContext();

  const handleAddFilter = () => {
    onStateChange(CEvents.CREATE_EDIT_FILTERS);
  };

  return filters.length > 0 ? (
    <>
      <Table.Root size="sm" variant={"outline"}>
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeader>Filter Type</Table.ColumnHeader>
            <Table.ColumnHeader>Property</Table.ColumnHeader>
            <Table.ColumnHeader>Operator</Table.ColumnHeader>
            <Table.ColumnHeader>Value</Table.ColumnHeader>
            <Table.ColumnHeader></Table.ColumnHeader>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {filters.map((filter) => {
            return (
              <Table.Row
                key={`${filter.value[0]}-${filter.operator.constructor.name}`}
              >
                <>
                  <Table.Cell>
                    <Code>{filter_condition_to_string(filter)}</Code>
                  </Table.Cell>
                  <Table.Cell>
                    <Code>
                      {
                        propertyReferenceFromFilterCondition(filter)
                          .property_name
                      }
                    </Code>
                  </Table.Cell>
                  <Table.Cell>
                    <Code>{operator_to_string(filter.operator)}</Code>
                  </Table.Cell>
                  <Table.Cell>
                    <Code>{value_to_string(filter.value)}</Code>
                  </Table.Cell>
                  <Table.Cell>
                    <IconButton
                      size={"xs"}
                      variant={"outline"}
                      onClick={() => {
                        removeFilter(filter);
                      }}
                    >
                      <LuX />
                    </IconButton>
                  </Table.Cell>
                </>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table.Root>
      <Center my={8}>
        <Button onClick={handleAddFilter} size={"xs"} variant={"outline"}>
          <LuPlus /> Add Filter
        </Button>
      </Center>
    </>
  ) : (
    <EmptyState
      title="Your projection has no filters"
      description="Add filters to get started"
      buttonText="Add Filter"
      onClick={handleAddFilter}
    />
  );
};

export default FilterElements;
