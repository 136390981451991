"use client";

import { Flex } from "@chakra-ui/react";

import { useQueryContext } from "@/context/QueryContext";

const CodeBlock = () => {
  const { queryResponse } = useQueryContext();
  // TODO: remove once server adds line breaks
  const sqlWithLinebreaks = queryResponse?.sql
    .split("SELECT")
    .join("\nSELECT")
    .split("FROM")
    .join("\nFROM")
    .split("GROUP BY")
    .join("\nGROUP BY")
    .trim();

  return (
    <Flex borderRadius={"lg"} bg={"#121212"} p={4} color={"gray.100"}>
      <pre>{sqlWithLinebreaks}</pre>
    </Flex>
  );
};

export default CodeBlock;
