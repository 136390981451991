import { Button, Group } from "@chakra-ui/react";
import { LuPlus, LuSearch } from "react-icons/lu";

import { EmptyState } from "@/components/ui/empty-state";

const Empty = ({
  title,
  description,
  buttonText,
  onClick,
}: {
  title: string;
  description: string;
  buttonText: string;
  onClick: () => void;
}) => (
  <EmptyState
    border={"xs"}
    borderRadius={"md"}
    icon={<LuSearch />}
    title={title}
    description={description}
  >
    <Group>
      <Button onClick={onClick}>
        <LuPlus /> {buttonText}
      </Button>
    </Group>
  </EmptyState>
);

export default Empty;
